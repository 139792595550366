(window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
(document as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
(window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
(document as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
const events = [
    'scroll',
    'mouseenter',
    'mouseout',
    'mouseover',
    'mousewheel',
    'pointermove',
    'mousemove',
    'mouseenter',
    'mouseleave',
    'dragenter',
    'dragover',
    'dragleave'
  ];
(window as any).__zone_symbol__UNPATCHED_EVENTS = events; // disable patch specified eventNames
(document as any).__zone_symbol__UNPATCHED_EVENTS = events; // disable patch specified eventNames
